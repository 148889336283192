<template>
  <div
    v-if="visible"
    class="modal_window fixed left-0 top-0 z-40 flex h-full w-full overflow-hidden"
    @keydown.esc="closeDialog"
  >
    <div
      ref="dialogContainer"
      role="dialog"
      aria-modal="true"
      :aria-labelledby="id + '_label'"
      class="max-w-xl absolute inset-0 z-50 m-auto flex place-self-center self-center overflow-hidden rounded-none border-0 border-neutral-7 contrast:border-base-1 bg-base-2 shadow-xl sm:static sm:h-auto sm:w-auto sm:rounded sm:border"
    >
      <div class="relative flex w-full flex-col overflow-hidden">
        <div
          class="flex justify-between rounded-t border-b border-neutral-7 contrast:border-base-1 bg-neutral-9 contrast:bg-c-secondary-0 p-1 align-middle"
        >
          <h3 :id="id + '_label'" class="m-1 font-normal">
            {{ dialogTitle }}
          </h3>
          <ControlButton
            :id="`${id}_close_window`"
            class="m-1 align-middle"
            button-style="button-compact"
            :inverted="false"
            :aria-label="closeTitle"
            @click="closeDialog"
          >
            <template #icon>
              <IconPzo name="close" />
            </template>
          </ControlButton>
        </div>
        <div ref="dialogContent" class="mb-16 overflow-auto p-4 sm:mb-0">
          <div v-if="!initialFocus" tabindex="0"></div>
          <slot></slot>
        </div>
        <div
          v-if="$slots.buttons"
          class="absolute bottom-0 left-0 flex w-full flex-row-reverse justify-start bg-base-2 sm:static sm:gap-2 sm:px-2 sm:pb-2 sm:pl-48"
        >
          <slot name="buttons"> </slot>
        </div>
      </div>
    </div>
    <ScreenDisable class="absolute opacity-40"></ScreenDisable>
  </div>
</template>

<script setup lang="ts">
import ControlButton from "./ControlButton.vue";
import ScreenDisable from "./ScreenDisable.vue";
import { computed, ref, watch, nextTick } from "vue";
import { useFocusTrap } from "../composables/useFocusTrap";
import { useFocusableElements } from "../composables/useFocusableElements";
import IconPzo from "./IconPzo.vue";
interface Props {
  dialogTitle: string;
  closeTitle: string;
  id: string;
  modelValue: boolean;
  initialFocus?: boolean;
  focusOnClose: HTMLElement | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  initialFocus: true,
});

const dialogContainer = ref<HTMLElement>();
const dialogContent = ref<HTMLElement>();
const focusTrap = useFocusTrap(dialogContainer);
const focusableElements = useFocusableElements(dialogContent);

const visible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

watch(visible, async (newValue) => {
  if (newValue) {
    await nextTick();
    focusTrap.updateFocusableElements();
    focusTrap.initFocusTrap();
    focusableElements.updateFocusableElements();
    focusableElements.firstFocusableElement.value?.focus();
  } else {
    props.focusOnClose?.focus();
    focusTrap.clearFocusTrap();
  }
});

function closeDialog() {
  visible.value = false;
}

defineExpose({ closeDialog });
</script>
